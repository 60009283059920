.pending {
    color: var(--grey100);
}

.action-button {
    transition: all 0.5s;
    font-size: 16px;
    width: 100%;

    &.submitted {
        cursor: default;
    }

    &[disabled] {
        user-select: none;

        &.primary {
            background-color: var(--blue300);
            border-color: var(--blue300);

            &:hover {
                background: var(--blue200);
            }
        }

        &.secondary {
            &.hover {
                background-color: var(--white);
                color: var(--blue200);
            }
        }

        &.tertiary {
            background: var(--red300);
            border: 1px solid var(--red300);

            &:hover {
                background: var(--red200);
            }
        }
    }

    &.countdown {
        margin-bottom: 0 !important;
    }
}

.hover-wrapper {
    width: 100%;
    display: flex;

    &.countdown {
        margin-bottom: 0 !important;
    }

    @media (max-width: 500px) {
        text-align: center;
    }
}

.winning {
    background-color: var(--teal) !important;
    border-color: var(--teal) !important;
    cursor: default;
}

.inactive {
    background-color: var(--grey200) !important;
    border-color: var(--grey100) !important;
}

.switchWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;

    .switch {
        display: block;
    }
}

.currency {
    margin: 0 6px;
}

.primaryVariant {
    font-size: 14px;
}
