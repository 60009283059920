$tablet-narrow-width: 768px;

.leading {
    color: var(--teal);
}

.outbid,
.declined {
    color: var(--red300);
}

.pending {
    color: var(--grey100);
}

.closed {
    color: var(--grey100);
}

.seller-name {
    font-family: var(--font-primary-strong);
}

.register {
    border-bottom: 1px solid var(--grey400);
    padding-bottom: 32px;
    margin-bottom: 32px;

    @media (max-width: $tablet-narrow-width) {
        padding-bottom: 15px;
    }

    .register-body {
        color: var(--grey100);
        display: flex;
        flex-direction: column;
        font-family: var(--font-primary);
        font-size: 14px;

        @media (max-width: $tablet-narrow-width) {
            font-size: 12px;
        }
    }
}

.bidMessageIconRow {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.icon {
    margin-right: 6px;
}
