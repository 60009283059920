html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    font-family: var(--font-primary);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 22px;
    overflow-x: hidden;
    overflow-y: initial;
    position: absolute;
    width: 100%;
    margin: 0;
}

@media print {
    body {
        height: 98%;
    }
}

@media print {
    div[class^='ki-'] {
        display: none !important;
    }
}

body #content {
    height: 100%;
    width: 100%;
}

body .btn-primary:focus {
    outline: 0;
}

body .btn-primary:active {
    outline: 0;
}

body .btn-primary:active:focus {
    outline: 0;
}

#popover-trigger-click-root-close.popover {
    border: 1px solid var(--grey400);
    border-radius: 2px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.12);
}

.grecaptcha-badge {
    visibility: collapse !important;
}

.nav-tabs > li > a,
b,
strong,
optgroup,
kbd kbd,
label,
dt,
.close,
.label {
    font-weight: 400;
    font-family: var(--font-primary-strong);
}

/* cookiebot */

#CybotCookiebotDialogBody {
    max-width: var(--screen-lg-min) !important;
    margin: 0 auto;
    padding: 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#CybotCookiebotDialogBodyContent {
    max-width: 70%;
    padding: 12px 0 !important;
}

#CybotCookiebotDialogDetailBody {
    max-width: var(--screen-lg-min) !important;
}

#CybotCookiebotDialogBodyButtons {
    width: 30%;
    align-items: center;
    align-self: flex-end;
}

#CybotCookiebotDialog {
    box-shadow: none !important;
    border-top: 1px solid var(--grey400) !important;
    background: var(--beige100) !important;
    margin-top: 4px !important;
    padding-bottom: 37px !important;
}

#CybotCookiebotDialogBodyContentTitle {
    font-family: var(--font-secondary) !important;
    font-weight: 400 !important;
    font-size: 19px !important;
    line-height: 28px !important;
    letter-spacing: 0.5px !important;
    color: var(--black) !important;
}

#CybotCookiebotDialog a,
#CybotCookiebotDialogBodyContentControls {
    font-family: var(--font-primary) !important;
    font-size: 11px;
    line-height: 12px !important;
    letter-spacing: 0.5px !important;
}

#CybotCookiebotDialog div {
    font-family: var(--font-primary) !important;
    letter-spacing: 0.5px !important;
    line-height: 20px !important;
    color: var(--black);
}

#CybotCookiebotDialogBodyContentText p {
    margin: 0;
}

#CybotCookiebotDialogDetail {
    background: var(--beige100);
    padding: 0;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypeDetails {
    background: var(--white);
    padding: 11px 8px 12px 14px;
}

#CybotCookiebotDialogBodyButtonAccept {
    font-family: var(--font-primary-strong) !important;
    float: right;
}

#CybotCookiebotDialogBodyButtons a {
    text-transform: uppercase;
    font-family: var(--font-primary-strong) !important;
    font-weight: 400;
    padding: 6px 10px;
    margin: 0;
    transition: all 0.25s;
    letter-spacing: 1px !important;
}

#CybotCookiebotDialogBodyButtonAccept:hover {
    background: var(--blue100);
}

#CybotCookiebotDialogDetailFooter {
    background: var(--beige100);
    opacity: 1 !important;
    padding: 3px;
}

#CybotCookiebotDialogDetailBodyContentTabs a {
    font-family: var(--font-secondary) !important;
    font-size: 15px !important;
    background: var(--beige100) !important;
    border: none;
    padding: 10px;
    letter-spacing: 0.5px !important;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink {
    font-family: var(--font-primary) !important;
    letter-spacing: 0.5px !important;
    font-size: 12.5px !important;
    text-decoration: underline !important;
    text-transform: none;
    padding: 7px 0 0 !important;
    color: var(--grey100) !important;
    border: none !important;
    margin: 0 12px 0 0 !important;
    background-image: none !important;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink:hover {
    color: var(--blue200);
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes a {
    font-size: 10px !important;
    font-family: var(--font-primary-strong) !important;
    border: none;
}

#CybotCookiebotDialogDetailBodyContent {
    border: 1px solid var(--grey400) !important;
    background: var(--white) !important;
}

.CybotCookiebotDialogDetailBodyContentTabsItemSelected {
    border-bottom: 3px solid var(--blue200) !important;
}

#CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    padding-top: 8px !important;
    background: var(--white) !important;
}

.CybotCookiebotDialogDetailBodyContentCookieTypeTable thead th {
    text-transform: uppercase;
    color: var(--grey200) !important;
    font-family: var(--font-primary-strong);
    background: none !important;
}

.CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td {
    padding: 4px 0 !important;
    margin-left: 1px;
    font-size: 11px !important;
}

.CybotCookiebotDialogDetailBodyContentCookieContainerTypes:hover,
.CybotCookiebotDialogDetailBodyContentCookieContainerTypesSelected {
    background: var(--grey500);
}

.CybotCookiebotDialogDetailBodyContentCookieTypeTable tbody td,
.CybotCookiebotDialogDetailBodyContentCookieTypeTable thead th {
    padding: 6px !important;
    overflow: auto !important;
    overflow-wrap: break-word;
    font-size: 11px !important;
    border: none !important;
}

#CybotCookiebotDialogDetailBodyContent a {
    color: var(--grey100) !important;
    background: var(--white);
}

a.CybotCookiebotDialogDetailBodyContentTabsItem:focus,
a.CybotCookiebotDialogDetailBodyContentTabsItem:hover {
    background-color: none !important;
}

#CybotCookiebotDialogDetailBodyContentTextAbout {
    background: var(--white);
}

.CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
    background: transparent;
}

@media (max-width: 767px) {
    #CybotCookiebotDialogBody {
        flex-direction: column;
    }

    #CybotCookiebotDialogBodyContentTitle {
        font-size: 16px;
    }

    #CybotCookiebotDialogBodyContent {
        max-width: 100%;
        padding: 7px 15px !important;
    }

    #CybotCookiebotDialogBodyButtons {
        width: 100%;
        padding: 0 15px;
    }
    #content {
        overflow-x: hidden;
    }
}

/* bootstrap fix */

table {
    border-spacing: 0;
    border-collapse: collapse;
}
.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee;
}
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}
* {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
